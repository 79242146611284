const StickyState = require('sticky-state');

// position: stickyがブラウザで使えるかチェックするための関数
function detectSticky() {
    const div = document.createElement('div');
    div.style.position = 'sticky';
    // position: stickyがブラウザで使えればtrue、使えなければfalseを返す
    return div.style.position.indexOf('sticky') !== -1;
  }
  
  // .stickyが指定されている要素に対してposition: stickyを適用させる関数
  function callStickyState() {
    // position: stickyを適用させたい要素を引数に指定し、
    // StickyStateをnewしてインスタンスを返す
    return new StickyState(document.querySelectorAll('.sticky'));
  }
  
  // もしブラウザでposition: stickyが使えなければ、
  // callStickyState関数を呼び出す
  if (!detectSticky()) {
    callStickyState();
}